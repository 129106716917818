import { WCAGLevel } from "@getsubly/common";
import { RiCheckLine, RiCloseLine } from "@remixicon/react";

type WcagStatusProps = {
  wcagLevel: WCAGLevel;
};
export const WcagStatus: React.FC<WcagStatusProps> = ({ wcagLevel }) => {
  switch (wcagLevel) {
    case WCAGLevel.PASS_AAA:
      return (
        <div className="tw-inline-flex tw-gap-2 tw-whitespace-nowrap">
          <RiCheckLine className="tw-h-5 tw-w-5 tw-min-w-5 tw-text-success-600" />
          Pass. AAA Compliant
        </div>
      );
    case WCAGLevel.PASS_AA:
      return (
        <div className="tw-inline-flex tw-gap-2 tw-whitespace-nowrap">
          <RiCheckLine className="tw-h-5 tw-w-5 tw-min-w-5 tw-text-success-600" />
          Pass. AA Compliant
        </div>
      );
    case WCAGLevel.PASS_A:
      return (
        <div className="tw-inline-flex tw-gap-2 tw-whitespace-nowrap">
          <RiCloseLine className="tw-h-5 tw-w-5 tw-min-w-5 tw-text-destructive-600" /> Fails AA Compliance
        </div>
      );
    case WCAGLevel.FAIL:
      return (
        <div className="tw-inline-flex tw-gap-2 tw-whitespace-nowrap">
          <RiCloseLine className="tw-h-5 tw-w-5 tw-min-w-5 tw-text-destructive-600" />
          Fails A (Not Accessible)
        </div>
      );
    default:
      return "-";
  }
};
